import CookieBaner from "../../Components/CookieBaner/CookieBaner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./Prywatnosc.sass";
import styled from "styled-components";
import { usePdf } from "@mikecousins/react-pdf";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import ContactDialog from "../../Components/ContactDialog/ContactDialog";

const PolitykaCookies = () => {
  return (
    <>
      <Header />
      <div className="content">
        <iframe
          className="iframe"
          src="/static/cookies.html"
          frameBorder="0"
          height="4200"
        ></iframe>
      </div>
      <Footer />
      <ContactDialog />
      <CookieBaner />
    </>
  );
};

export default PolitykaCookies;

const CookiesStyled = styled.div`
  background-color: white;
  padding: 62px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const H1 = styled.h1`
  color: #166aad;
`;

const Justify = styled.div`
  width: 100%;
  text-align: justify;
`;

const Button = styled.button`
  border: 1px solid gray;
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
`;
