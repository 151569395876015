import './Sections.sass';

const SectionBox = (props) => {
  return (
    <div className={`section-box ${props.className}`}>
      {props.children}
    </div>
  )
}

export default SectionBox;