export default {
  translation: {
    header: {
      logoAlt: "Instytut Umiejętności Zawodowych Blu",
      menu: {
        aboutUs: "O nas",
        trainings: "Szkolenia",
        calendar: "Kalendarz",
        contact: "Kontakt",
      },
    },
    main: {
      title: "Odkryj Nową Jakość w Rozwoju Zawodowym z Blu Professional Skills Institute!",
      paragraph: "Dlaczego warto inwestować w swoje umiejętności? Odpowiedź jest prosta: sukces zawodowy zaczyna się od ciągłego doskonalenia. Blu Professional Skills Institute to firma szkoleniowa gotowa dostarczyć Ci niezbędne narzędzia do osiągnięcia wyjątkowych rezultatów w dzisiejszym dynamicznym świecie biznesu.",
    },
    events: {
      calendar: "Kalendarium",
      upcomingEvents: "Nadchodzące Szkolenia",
    },
    aboutUs: {
      ourAdvantages: "Nasze Atuty:",
      diverseOffering: {
        title: "Zróżnicowana Oferta:",
        description: "Niezależnie od branży, w której się rozwijasz, mamy to, czego potrzebujesz. Od Księgowości po Montaż Video - oferujemy bogaty wybór dziedzin, w których zdobędziesz specjalistyczną wiedzę.",
      },
      experiencedExperts: {
        title: "Doświadczeni Eksperci:",
        description: "Nasz zespół składa się z doświadczonych profesjonalistów z praktycznym know-how. To gwarancja, że nasze szkolenia są praktyczne, aktualne i dostosowane do realiów rynku.",
      },
      interactiveTeachingMethods: {
        title: "Interaktywne Metody Nauczania:",
        description: "Nauka to nie tylko słuchanie wykładów. Nasze interaktywne podejście uczy poprzez praktykę, studia przypadków i projekty. Dzięki temu zdobywasz umiejętności, które od razu możesz zastosować w pracy.",
      },
      smallGraphics: {
        title: "Mała Grafika - Tematyczny Akcent:",
        description: "Obok tekstu znajdziesz miniaturkę przedstawiającą różnorodność naszych dziedzin, oddającą esencję naszych szkoleń. Każdy obszar, od IT po Zarządzanie, jest reprezentowany w naszej ofercie.",
      },
      callToAction: "Nie czekaj - rozpocznij podróż ku nowym możliwościom z Blu Professional Skills Institute. Inwestycja w siebie zawsze przynosi największe zyski. Zdobądź umiejętności, które wyróżnią Cię na tle konkurencji. Zapisz się już dziś i stwórz solidne fundamenty swojej kariery zawodowej!",
    },
    trainingSections: {
      accounting: "Księgowość",
      administration: "Administracja",
      itIndustry: "Branża IT",
      programming: "Programowanie",
      commerce: "Handel",
      marketingAndAdvertising: "Marketing i Reklama",
      management: "Zarządzanie",
      videoEditing: "Montaż Video",
    },
    certificates: {
      title: "Wzory Certyfikatów do Pobrania",
    },
    footer: {
      onKRSNumber: "Pod numerem KRS: 0001051983",
      addressDetails: "UL. KIJOWSKA 44, 85-703 Bydgoszcz",
      registrationDetails: "Data wpisu do Rejestru Przedsiębiorców 2023-08-09",
    },
    contactDialog: {
      header: "Bezpłatna Konsultacja",
      giveContactData: "Podaj swój numer telefonu. Oddzwonimy jak najszybciej.",
      send: "Wyślij",
    },
    cookieBanner: {
      message: "Ta strona używa ciasteczek (cookies), aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.",
      privacyPolicyLink: "Polityka Prywatności",
      cookiesPolicyLink: "Polityka Cookies",
      acceptButton: "Akceptuję",
    },
    eventCard: {
      unavailable: "BRAK MIEJSC",
    },
    cards: {
      administration: {
        title: "Administracja",
        description: "Inwestycja w umiejętności administracyjne przekłada się na płynne funkcjonowanie firmy. Nasze szkolenia doskonalić będą Twoje zdolności organizacyjne, komunikacyjne oraz zarządzanie czasem, co pozwoli Ci efektywnie działać w roli administratora.",
      },
      commerce: {
        title: "Handel",
        description: "Skuteczne strategie sprzedaży i negocjacji są kluczowe w dzisiejszym konkurencyjnym środowisku. Nasze szkolenia z zakresu handlu rozwijają umiejętności interpersonalne, uczy skutecznych technik sprzedażowych oraz budowania trwałych relacji z klientami.",
      },
      it: {
        title: "Branża IT",
        description: "W dzisiejszym cyfrowym świecie umiejętności związane z IT są kluczem do sukcesu. Nasze szkolenia IT przygotują Cię do wyzwań związanych z technologią, zapewniając solidną wiedzę w obszarach takich jak sieci komputerowe, bezpieczeństwo danych czy rozwiązywanie problemów technicznych.",
      },
      accounting: {
        title: "Księgowość",
        description: "Zyskaj dogłębną wiedzę z zakresu finansów i rachunkowości, co pozwoli Ci skutecznie zarządzać finansami firm. Nasze szkolenia pomogą Ci zrozumieć skomplikowane procedury podatkowe i sprawozdawcze, co przyczyni się do profesjonalnego rozwoju Twojej kariery w księgowości.",
      },
      marketing: {
        title: "Marketing i Reklama",
        description: "Wyróżnij się w gąszczu informacji dzięki umiejętnościom marketingowym. Nasze szkolenia pokażą Ci, jak efektywnie promować produkty i usługi, wykorzystując narzędzia online i offline oraz analizując trendy rynkowe, co przyczyni się do zwiększenia widoczności marki.",
      },
      programming: {
        title: "Programowanie",
        description: "Świat opiera się na kodzie, dlatego umiejętność programowania otwiera drzwi do nieograniczonych możliwości. Nasze kursy programowania nauczą Cię języków takich jak Java, Python czy C++, umożliwiając tworzenie innowacyjnych rozwiązań i aplikacji.",
      },
      video: {
        title: "Montaż Video",
        description: "W dobie wizualnej komunikacji, umiejętność montażu video to atut nie tylko dla profesjonalistów od multimediów. Nasze kursy nauczą Cię obsługi programów do edycji wideo, pozwalając tworzyć atrakcyjne treści wizualne, które przyciągną uwagę odbiorców.",
      },
      management: {
        title: "Zarządzanie",
        description: "Liderzy są kształtowani, nie urodzili się jako liderzy. Nasze szkolenia z zakresu zarządzania rozwijają umiejętności przywódcze, delegowanie zadań, rozwiązywanie konfliktów oraz skuteczne motywowanie zespołu, co przekłada się na efektywność działania firmy.",
      },
    },
    event: {
      duration: "Czas trwania:",
      tutors: "Prowadzący:",
      price: "Cena:",
      description: "Opis:",
      or: "lub",
      contact: "Skontaktuj się z nami w sprawie zakupu",
      email: "Wyślij e-mail"
    }
  }
}