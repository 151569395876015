import { useParams } from "react-router-dom";
import CookieBaner from "../../Components/CookieBaner/CookieBaner";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Szkolenie.sass";
import events from "../../content/content.json";
import Error404 from "../Error404/Error404";
import { useTranslation } from "react-i18next";

const Szkolenie = () => {
  const { category, name } = useParams();
  const { t } = useTranslation();
  let event;
  try {
    event = events[category].filter((event) => {
      return event.title === name;
    })[0];
  } catch (err) {
    return <Error404 />;
  }
  if (!event) return <Error404 />;
  return (
    <>
      <Header />
      <div className="content">
        <div className="event-layout">
          <div>
            <img className="event__image" src={event.image} alt={event.title} />
            <br />
            <br />
            <h1>{event.title}</h1>
            <p>
              <b>
                {t("event.description")}
              </b>
              <br />
              {event.description}
            </p>
            <p>
              <b>
                {t("event.tutors")}
              </b> {event.tutors.join(", ")}
            </p>
            <p>
              <b>
                {t("event.duration")}
              </b> {event.duration}h
            </p>
            <p>
              <b>
                {t("event.price")}
              </b> {event.price} zł
            </p>
            <br />
            <p className="contact">
              <a href="tel:+48525222249">
                {t("event.contact")}
              </a>
              <br />
              {t("event.or")}
              <br />
              <a href="mailto:edyta.chmura@bpsi.edu.pl">
                {t("event.email")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <CookieBaner />
    </>
  );
};

export default Szkolenie;
