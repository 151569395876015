import SectionBox from "../../Components/Sections/SectionBox";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import EventCardSmall from "../../Components/EventCardSmall/EventCardSmall";
import "./Home.sass";
import { useEffect, useState, useRef } from "react";
import Ksiegowosc from "../inHomeFrames/Ksiegowosc/Ksiegowosc";
import Administracja from "../inHomeFrames/Administracja/Administracja";
import IT from "../inHomeFrames/IT/IT";
import Programowanie from "../inHomeFrames/Programowanie/Programowanie";
import Handel from "../inHomeFrames/Handel/Handel";
import Marketing from "../inHomeFrames/Marketing/Marketing";
import Zarzadzanie from "../inHomeFrames/Zarzadzanie/Zarzadzanie";
import Video from "../inHomeFrames/Video/Video";
import ContactDialog from "../../Components/ContactDialog/ContactDialog";
import { useTranslation } from "react-i18next";

// MEDIA
import dokument from "./media/dokument.png";
import prelegent from "./media/prelegent.png";
import cert1 from "./media/cert1.webp";
import cert2 from "./media/cert2.webp";
import cert3 from "./media/cert3.webp";
import cert4 from "./media/cert4.webp";
import CookieBaner from "../../Components/CookieBaner/CookieBaner";
import events from "../../content/content.json";

const Home = () => {
  const contactDialogRef = useRef();
  const { t } = useTranslation();
  window.t = t;

  const [currentFrame, setCurrentFrame] = useState("#ksiegowosc");
  const [isUserOnPage, setIsUserOnPage] = useState(true);

  useEffect(() => {
    document.title = "Blu Professional";

    window.addEventListener("hashchange", onHashChange);
    window.addEventListener("resize", onResize);
    document.addEventListener("mouseleave", setIsUserOnPage(false));
    document.addEventListener("mouseenter", setIsUserOnPage(true));
    window.addEventListener("load", onLoad);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("hashchange", onHashChange);
      document.removeEventListener("mouseleave", setIsUserOnPage(false));
      document.removeEventListener("mouseenter", setIsUserOnPage(true));
      window.removeEventListener("load", onLoad);
    };
  }, []);

  const onLoad = async () => {
    setTimeout(showDialog, 20000); // 20 seconds (default)
    const href = window.location.href.split("/")[3];
    if (href[0] !== "#") return;
    try {
      document.querySelector(href).click();
    } catch (err) {
      return;
    }
  };

  const showDialog = () => {
    contactDialogRef.current.showModal();
  };

  const onHashChange = (event) => {
    document.querySelector(event.newURL.split("/")[3]).click();
    window.scrollTo(0, window.scrollY - 100);
  };

  const onNavHover = (event) => {
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    if (
      !(
        event.target.className === "nav__option" ||
        event.target.className === "nav__option--active"
      )
    )
      return;
    const indicator = document.querySelector(".nav__indicator--hover");
    indicator.style.background = "lightgray";
    indicator.style.left = `${event.target.getBoundingClientRect().left - offsets.left
      }px`;
    indicator.style.top = `${event.target.getBoundingClientRect().top - offsets.top
      }px`;
    indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
  };

  const onNavHoverLeave = () => {
    const indicator = document.querySelector(".nav__indicator--hover");
    indicator.style.background = "";
  };

  const onNavOptionChange = (event) => {
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    if (
      !(
        event.target.className === "nav__option" ||
        event.target.className === "nav__option--active"
      )
    )
      return;

    document.querySelector(".nav__option--active").className = "nav__option";
    const indicator = document.querySelector(".nav__indicator");
    indicator.style.left = `${event.target.getBoundingClientRect().left - offsets.left
      }px`;
    indicator.style.top = `${event.target.getBoundingClientRect().top - offsets.top
      }px`;
    indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
    event.target.className = "nav__option--active";
    setCurrentFrame(event.target.getAttribute("href"));
  };

  const onResize = () => {
    const lastHoverElem = document.querySelector(".nav__option--active");
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    const indicator = document.querySelector(".nav__indicator");
    const hoverIndicator = document.querySelector(".nav__indicator");
    indicator.style.left = `${lastHoverElem.getBoundingClientRect().left - offsets.left
      }px`;
    indicator.style.top = `${lastHoverElem.getBoundingClientRect().top - offsets.top
      }px`;
    indicator.style.width = `${lastHoverElem.getBoundingClientRect().width}px`;
    hoverIndicator.style.left = `${lastHoverElem.getBoundingClientRect().left - offsets.left
      }px`;
    hoverIndicator.style.top = `${lastHoverElem.getBoundingClientRect().top - offsets.top
      }px`;
    hoverIndicator.style.width = `${lastHoverElem.getBoundingClientRect().width
      }px`;
  };

  const getCurrentFrameComponent = () => {
    if (currentFrame === "#ksiegowosc") return <Ksiegowosc />;
    else if (currentFrame === "#administracja") return <Administracja />;
    else if (currentFrame === "#it") return <IT />;
    else if (currentFrame === "#programowanie") return <Programowanie />;
    else if (currentFrame === "#handel") return <Handel />;
    else if (currentFrame === "#marketing-i-reklama") return <Marketing />;
    else if (currentFrame === "#zarzadzanie") return <Zarzadzanie />;
    else if (currentFrame === "#montaz-video") return <Video />;
  };

  const getAndSortEvents = () => {
    const allEvents = [];
    for (const category in events) {
      const spread = [...events[category]];
      spread.forEach((item) => {
        item.category = category;
      });
      allEvents.push(...spread);
    }

    allEvents.sort((event1, event2) => {
      // TODO add sorting to dates table
      const date1 = new Date(event1.dates[0]);
      const date2 = new Date(event2.dates[0]);
      return date1 - date2; // ascending
    });

    return allEvents;
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="home-main-layout">
          <SectionBox>
            <h3>
              {t("main.title")}{" "}
              <span className="color--primary">
                Blu Professional Skills Institute
              </span>
              !
            </h3>
            <p>
              {t("main.paragraph")}
            </p>
          </SectionBox>
          <SectionBox>
            <div id="kalendarz">
              <h3>{t("events.calendar")}</h3>
              <h4>{t("events.upcomingEvents")}</h4>
            </div>
            {getAndSortEvents().map((event, index) => {
              if (index >= 4) return null;
              return (
                <EventCardSmall
                  category={event.category}
                  key={index}
                  available={event.available}
                  image={event.image}
                  title={event.title}
                  date={event.dates[0]}
                />
              );
            })}
          </SectionBox>
        </div>
        <div className="section layout--2-1" id="o-nas">
          <div>
            <h3>{t("aboutUs.ourAdvantages")}</h3>
            <br />
            <div className="text--justify">
              <ol>
                <li>
                  <b>{t("aboutUs.diverseOffering.title")}</b> {t("aboutUs.diverseOffering.description")}
                </li>
                <li>
                  <b>{t("aboutUs.experiencedExperts.title")} </b> {t("aboutUs.experiencedExperts.description")}
                </li>
                <li>
                  <b>{t("aboutUs.interactiveTeachingMethods.title")}</b> {t("aboutUs.interactiveTeachingMethods.description")}
                </li>
                <li>
                  <b>{t("aboutUs.smallGraphics.title")}</b> {t("aboutUs.smallGraphics.description")}
                </li>
              </ol>
              <br />
              <div className="font--body-large">
                {t("aboutUs.callToAction")}
              </div>
            </div>
          </div>
          <img src={prelegent} alt="Prelegent" />
        </div>
        <nav
          id="szkolenia"
          className="nav"
          onMouseMove={onNavHover}
          onMouseLeave={onNavHoverLeave}
          onClick={onNavOptionChange}
        >
          <div className="nav__indicator--hover"></div>
          <div className="nav__indicator"></div>
          <a id="ksiegowosc" className="nav__option--active" href="#ksiegowosc">
            {t("trainingSections.accounting")}
          </a>
          <a id="administracja" className="nav__option" href="#administracja">
            {t("trainingSections.administration")}
          </a>
          <a id="it" className="nav__option" href="#it">
            {t("trainingSections.itIndustry")}
          </a>
          <a id="programowanie" className="nav__option" href="#programowanie">
            {t("trainingSections.programming")}
          </a>
          <a id="handel" className="nav__option" href="#handel">
            {t("trainingSections.commerce")}
          </a>
          <a
            id="marketing-i-reklama"
            className="nav__option"
            href="#marketing-i-reklama"
          >
            {t("trainingSections.marketingAndAdvertising")}
          </a>
          <a id="zarzadzanie" className="nav__option" href="#zarzadzanie">
            {t("trainingSections.management")}
          </a>
          <a id="montaz-video" className="nav__option" href="#montaz-video">
            {t("trainingSections.videoEditing")}
          </a>
        </nav>
        <SectionBox className="w100">{getCurrentFrameComponent()}</SectionBox>
        <div className="section w100 grid-gap">
          <h2>
            {t("certificates.title")}
          </h2>
          <div className="layout--4-2 w100">
            <a
              href="/static/pdf/cert1.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={cert1} alt="cert1" />
            </a>
            <a
              href="/static/pdf/cert2.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={cert2} alt="cert2" />
            </a>
            <a
              href="/static/pdf/cert3.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={cert3} alt="cert3" />
            </a>
            <a
              href="/static/pdf/cert4.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={cert4} alt="cert4" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <ContactDialog ref={contactDialogRef} />
      <CookieBaner />
    </>
  );
};

export default Home;
