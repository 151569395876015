import './EventCardSmall.sass';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EventCardSmall = (props) => {
  const { t } = useTranslation();
  return (
    <Link className={props.available ? "event-card-small" : "event-card-small event-card-small--unavailable"} to={`/szkolenia/${props.category}/${props.title}`}>
      <div className="event-card-small__image-box" style={{backgroundImage: `url(${props.image})`}}>
        <span>{props.available ? "" : t("eventCard.unavailable")}</span>
      </div>
      <div className="event-card-small--details">
        <h5>{props.title}</h5>
        <p>{props.date}</p>
      </div>
    </Link>
  )
}

export default EventCardSmall;