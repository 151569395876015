import { changeLanguage } from "i18next";
import "./Header.sass";
import logo from "./logo-new.webp";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const expandCollapseMenu = () => {
    document.querySelector("nav").classList.toggle("expanded");
    document.querySelector(".svg").classList.toggle("svg--active");
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lng", language);
  };

  return (
    <header>
      <a href="/">
        <img
          className="header__logo"
          src={logo}
          alt="Blu Proffesional Skills Institute"
        />
      </a>
      <div className="header__mobile-nav-button" onClick={expandCollapseMenu}>
        <svg width="40" height="32" className="svg">
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
        </svg>
      </div>
      <nav onClick={expandCollapseMenu}>
        <a href="/#o-nas">{t("header.menu.aboutUs")}</a>
        <a href="/#szkolenia">{t("header.menu.trainings")}</a>
        <a href="/#kalendarz">{t("header.menu.calendar")}</a>
        <a href="/#kontakt">{t("header.menu.contact")}</a>
        {
          i18n.language === "pl" ? (
            <a onClick={() => changeLanguage("en")}>
              <img className="flag" height="22" src="/static/en.webp" alt="EN" />
            </a>
          ) : (
            <a onClick={() => changeLanguage("pl")}>
              <img className="flag" height="22" src="/static/pl.webp" alt="PL" />
            </a>
          )
        }
      </nav>
    </header>
  );
};

export default Header;
