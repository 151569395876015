import EventCard from "../../../Components/EventCard/EventCard";
import '../InHomeFrames.sass';
import events from '../../../content/content.json';
import { useTranslation } from 'react-i18next';

const Marketing = () => {
  const { t } = useTranslation(); 
  return (
    <div className="section w100 grid-gap">
      <h3>
        {t('cards.marketing.title')}
      </h3>
      <p>
        {t('cards.marketing.description')}
      </p>
      <div className="events-layout">
      {
          events.marketing.map((event, key) => {
            return <EventCard
              key={key}
              category="marketing"
              price={event.price}
              title={event.title}
              label={event.label}
              description={event.description}
              duration={event.duration}
              tutors={event.tutors}
              image={event.image}
            />
          })
        }
        </div>
    </div>
  )
}

export default Marketing;