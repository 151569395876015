import { Link } from 'react-router-dom';
import { useRef } from 'react';
import './EventCard.sass';
import { useTranslation } from 'react-i18next';

const EventCard = (props) => {
  const { t } = useTranslation();
  const eventCardBoxRef = useRef();
  const onMouseEnter = () =>{
    eventCardBoxRef.current.classList = "event-card__box--visible";
  }

  const onMouseLeave = () => {
    eventCardBoxRef.current.classList = "event-card__box--hidden";
  }

  return (
    <Link to={`/szkolenia/${props.category}/${props.title}`} className={props.available ? "event-card" : "event-card event-card--unavailable"} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="event-card__image" style={{backgroundImage: `url('${props.image}')`}}>
        {
          props.available ? null : t("eventCard.unavailable")
        }
      </div>
      <div className="event-card__box">
        <div className="event-card__box--left">
          <h5>{props.title}</h5>
          <p>{props.label}</p>
        </div>
        <div className="event-card__box--right">
          <p>{props.duration} godzin</p>
          <h5>od {props.price} zł</h5>
        </div>
        <div ref={eventCardBoxRef} className="event-card__box--hidden">
          <p>{props.description}</p>
        </div>
      </div>
    </Link>
  )
}

export default EventCard