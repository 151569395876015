import './Footer.sass';
import mailIcon from './mailIcon.webp';
import phoneIcon from './phoneIcon.webp';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer" id="kontakt">
      <div className="footer__element footer__first">
        <h3>
          BLU PROFESSIONAL SKILLS INSTITUTE sp. z o. o.
        </h3>
        <br />
        <p>
        {t("footer.onKRSNumber")}<br />
        {t("footer.addressDetails")}<br />
        {t("footer.registrationDetails")}<br />
        NIP 5543015675
        </p>
      </div>
      <div className="footer__element footer__center">
        <div className="footer__contact-data-grid">
          <div>
            <img src={mailIcon} alt="Mail" />
          </div>
          <div>
            <a href="mailto:edyta.chmura@bpsi.edu.pl">edyta.chmura@bpsi.edu.pl</a>
            <a href="mailto:krzysztof.chmura@bpsi.edu.pl">krzysztof.chmura@bpsi.edu.pl</a>
            <a href="mailto:katarzyna.chmura@bpsi.edu.pl">katarzyna.chmura@bpsi.edu.pl</a>
          </div>
        </div>
        <div className="footer__contact-data-grid">
          <div>
            <img src={phoneIcon} alt="Telefon" />
          </div>
          <div>
            <a href="tel:+48525222249">(+48) 52 522 22 49</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;