import { forwardRef, useTransition } from "react";
import './ContactDialog.sass';
import { useTranslation } from "react-i18next";

const ContactDialog = forwardRef((props, ref) => {
  const closeModal = () => {
    ref.current.close();
  }
  const { t } = useTranslation();

  return (
    <dialog ref={ref} className="contact-dialog">
      <div className="contact-dialog__image">
        <svg onClick={closeModal} className="contact-dialog__close" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path d="M27.4166 3.28584L24.7141 0.583344L14 11.2975L3.28581 0.583344L0.583313 3.28584L11.2975 14L0.583313 24.7142L3.28581 27.4167L14 16.7025L24.7141 27.4167L27.4166 24.7142L16.7025 14L27.4166 3.28584Z" fill="white" />
        </svg>
        <h2>{t("contactDialog.header")}</h2>
      </div>
      <div className="contact-dialog__content">
        <p>
          {t("contactDialog.giveContactData")}
        </p>
        <form className="form">
          <input className="form--input" placeholder="+48 000 000 000" type="tel"/>
          <input className="form--button" type="submit" value={t("contactDialog.send")} />
        </form>
      </div>
    </dialog>
  )
})

export default ContactDialog;