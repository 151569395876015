import styled from "styled-components";
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const CookieBaner = () => {
  const banerRef = React.useRef();
  const { t } = useTranslation();

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const hideBaner = () => {
    setCookie("baner", "true", 7);
    banerRef.current.style.display = "none";
  }

  return (
    <>
      {
        getCookie("baner") ? null : (
        <Baner ref={banerRef}>
          {console.log(t("cookieBaner.message"))}
          <div>{t("cookieBanner.message")}<Link to="/cookies">{t("cookieBanner.cookiesPolicyLink")}</Link> <Link to="/polityka-prywatnosci">{t("cookieBanner.privacyPolicyLink")}</Link> </div> <Button onClick={() => hideBaner()}>{t("cookieBanner.acceptButton")}</Button></Baner>)
      }
    </>
  )
}

export default CookieBaner;

const Baner = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: white;
  padding: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  box-shadow: 00px 0px 10px hsla(0, 0%, 0%, 0.5);
  border-radius: 4px;
  z-index: 100;
`;

const Button = styled.div`
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
  }
`;