import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationPL from './locales/pl';
import translationEN from './locales/en';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lng") || "en",
  fallbackLng: "pl",
  interpolation: {
    escapeValue: false,
  },
  languages: ["pl", "en"],
  resources: {
    en: translationEN,
    pl: translationPL,
  },
});

export default i18n;
