export default {
  translation: {
    header: {
      logoAlt: "Blu Professional Skills Institute",
      menu: {
        aboutUs: "About Us",
        trainings: "Trainings",
        calendar: "Calendar",
        contact: "Contact",
      },
    },
    main: {
      title: "Discover New Quality in Professional Development with Blu Professional Skills Institute!",
      paragraph: "Why is it worth investing in your skills? The answer is simple: professional success starts with continuous improvement. Blu Professional Skills Institute is a training company ready to provide you with the necessary tools to achieve exceptional results in today's dynamic business world.",
    },
    events: {
      calendar: "Calendar",
      upcomingEvents: "Upcoming Events",
    },
    aboutUs: {
      ourAdvantages: "Our Advantages:",
      diverseOffering: {
        title: "Diverse Offering:",
        description: "Regardless of the industry you are developing in, we have what you need. From Accounting to Video Editing - we offer a rich selection of fields in which you will gain specialized knowledge.",
      },
      experiencedExperts: {
        title: "Experienced Experts:",
        description: "Our team consists of experienced professionals with practical know-how. This guarantees that our training is practical, up-to-date, and adapted to market realities.",
      },
      interactiveTeachingMethods: {
        title: "Interactive Teaching Methods:",
        description: "Learning is not just about listening to lectures. Our interactive approach teaches through practice, case studies, and projects. This way, you gain skills that you can immediately apply at work.",
      },
      smallGraphics: {
        title: "Small Graphics - Thematic Accent:",
        description: "Next to the text, you will find a thumbnail representing the diversity of our fields, capturing the essence of our training. Every area, from IT to Management, is represented in our offer.",
      },
      callToAction: "Don't wait - start your journey towards new opportunities with Blu Professional Skills Institute. Investing in yourself always yields the biggest returns. Gain skills that will set you apart from the competition. Enroll today and build a solid foundation for your professional career!",
    },
    trainingSections: {
      accounting: "Accounting",
      administration: "Administration",
      itIndustry: "IT Industry",
      programming: "Programming",
      commerce: "Commerce",
      marketingAndAdvertising: "Marketing and Advertising",
      management: "Management",
      videoEditing: "Video Editing",
    },
    certificates: {
      title: "Downloadable Certificate Templates",
    },
    footer: {
      onKRSNumber: "Under the KRS number: 0001051983",
      addressDetails: "KIJOWSKA 44, 85-703 Bydgoszcz",
      registrationDetails: "Registration date in the Register of Entrepreneurs 2023-08-09",
    },
    contactDialog: {
      header: "Free Consultation",
      giveContactData: "Enter your phone number. We will call you back as soon as possible.",
      send: "Send",
    },
    cookieBanner: {
      message: "This site uses cookies to provide services at the highest level. Further use of the site means that you agree to their use.",
      privacyPolicyLink: "Privacy Policy",
      cookiesPolicyLink: "Cookies Policy",
      acceptButton: "Accept",
    },
    eventCard: {
      unavailable: "NO PLACES AVAILABLE"
    },
    cards: {
      administration: {
        title: "Administration",
        description: "Investing in administrative skills translates into smooth company operations. Our trainings will improve your organizational, communication, and time management skills, allowing you to act effectively in an administrative role.",
      },
      commerce: {
        title: "Commerce",
        description: "Effective sales and negotiation strategies are key in today's competitive environment. Our commerce trainings develop interpersonal skills, teach effective sales techniques, and building lasting relationships with clients.",
      },
      it: {
        title: "IT Industry",
        description: "In today's digital world, IT-related skills are key to success. Our IT trainings will prepare you for technology-related challenges, providing solid knowledge in areas such as computer networks, data security, or solving technical problems.",
      },
      accounting: {
        title: "Accounting",
        description: "Gain in-depth knowledge in finance and accounting, which will allow you to effectively manage company finances. Our trainings will help you understand complex tax and reporting procedures, contributing to your professional career development in accounting.",
      },
      marketing: {
        title: "Marketing and Advertising",
        description: "Stand out in the information clutter with marketing skills. Our trainings will show you how to effectively promote products and services, using both online and offline tools and analyzing market trends, which will contribute to increasing brand visibility.",
      },
      programming: {
        title: "Programming",
        description: "The world runs on code, so programming skills open doors to unlimited possibilities. Our programming courses will teach you languages such as Java, Python, or C++, enabling you to create innovative solutions and applications.",
      },
      video: {
        title: "Video Editing",
        description: "In the age of visual communication, video editing skills are an asset not only for multimedia professionals. Our courses will teach you how to use video editing software, allowing you to create attractive visual content that will attract audience attention.",
      },
      management: {
        title: "Management",
        description: "Leaders are made, not born. Our management trainings develop leadership skills, task delegation, conflict resolution, and effective team motivation, which translates into company efficiency.",
      },
    },
    event: {
      duration: "Duration:",
      tutors: "Tutors:",
      price: "Price:",
      label: "Label:",
      description: "Description:",
      or: "or",
      contact: "Contact us for purchase",
      email: "Send email"
    }
  },
};